import React, { useState } from "react";
import {
    Container,
    Typography,
    Button,
    Box,
    Grid2,
} from "@mui/material";

import Checkgreen from "../../../assets/icons/checkgreen.svg";
import CheckOrange from "../../../assets/icons/checkorange.svg";
import Afterpricingplan from "../../../assets/images/afterpricingplan.svg";
import { Tabs, Tab } from "@mui/material";

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
};

const PlanSection = () => {

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <Box className="planSection">
            <Container>
                <Grid2 container spacing={2} alignItems="center">
                    <Grid2 size={{ xs: 12, md: 12 }}>
                        <Box className="planSectionTop">
                            <Typography variant="h2">Plans and Pricing</Typography>
                            <Typography>
                                Choose the right option for your work
                                <br /> and develop your project.
                            </Typography>
                        </Box>
                    </Grid2>
                </Grid2>
            </Container>
            <Container className="customContainer">
                <Grid2 container spacing={2} alignItems="center">
                    <Grid2 size={{ xs: 12, md: 12 }}>
                        <Box className="planSectionBottom">
                            <Box sx={{ width: "100%", position: "relative" }}>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs example"
                                    className="pricingBox"
                                    sx={{
                                        "& .Mui-selected": {
                                            color: "#fff",
                                            background: "#1B8473",
                                            "& h6.MuiTypography-root": {
                                                color: "#fff",
                                            },
                                        },
                                        "& .MuiTabs-indicator": {
                                            display: "none",
                                        },
                                    }}
                                >
                                    <Tab
                                        label={
                                            <Box className="pricingBoxWrap">
                                                <Typography variant="h6">Yearly</Typography>{" "}
                                                <Typography>Save 33%</Typography>
                                            </Box>
                                        }
                                        id="tab-0"
                                        aria-controls="tabpanel-0"
                                    />
                                    <Tab
                                        label={
                                            <Box className="pricingBoxWrap">
                                                <Typography variant="h6">Monthly</Typography>
                                            </Box>
                                        }
                                        id="tab-1"
                                        aria-controls="tabpanel-1"
                                    />
                                </Tabs>
                                {/* <Box className="planSectionBottompattern">
                                    <img src={Afterpricingplan} alt="" />
                                </Box> */}
                                {/* <Box className="planSectionBottompatternLeft">
                                    <img src={Afterpricingplan} alt="" />
                                </Box> */}
                                <TabPanel className="tabPanel" value={value} index={0}>
                                    <Container
                                        sx={{
                                            padding: "0",
                                        }}
                                    >
                                        <Grid2
                                            container
                                            spacing={{ xs: 2, md: 2, lg: 5 }}

                                        >
                                            <Grid2 size={{ xs: 12, md: 4 }}>
                                                <Box className="pricingPlanBox">
                                                    <Box className="pricingPlanBoxTop">
                                                        <Typography variant="h5">
                                                            Free
                                                        </Typography>

                                                    </Box>
                                                    <Box className="pricingPlanBoxMiddle">
                                                        <Typography variant="h3">
                                                            $ 0.00
                                                            <Typography>/yearly</Typography>
                                                        </Typography>
                                                        <Typography className="pricingPlanBoxMiddleText">2 Months free</Typography>
                                                        <Button>Try it now</Button>
                                                    </Box>
                                                    <Box className="pricingPlanBoxBottom">
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>AI Humanization</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>Undetectable rewriting</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>500 words per input</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>Quick Support</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>Basic Mode</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={CheckOrange} alt="" />
                                                            </Box>
                                                            <Typography>Plagiarism-free outputs</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={CheckOrange} alt="" />
                                                            </Box>
                                                            <Typography>Advanced Mode</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={CheckOrange} alt="" />
                                                            </Box>
                                                            <Typography>API</Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid2>
                                            <Grid2 size={{ xs: 12, md: 4 }}>
                                                <Box className="pricingPlanBox pricingPlanBoxActive">
                                                    <Box className="pricingPlanBoxTop">
                                                        <Typography variant="h5">
                                                            Unlimited
                                                        </Typography>

                                                    </Box>
                                                    <Box className="pricingPlanBoxMiddle">
                                                        <Typography variant="h3">
                                                            $9.00
                                                            <Typography>/yearly</Typography>
                                                        </Typography>
                                                        <Typography className="pricingPlanBoxMiddleText">Unlimited Words/year</Typography>
                                                        <Button>Try it now</Button>
                                                    </Box>
                                                    <Box className="pricingPlanBoxBottom">
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>
                                                                Powerful AI humanizer
                                                            </Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>Unlimited words per input</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>100% human content score</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>Undetectable rewriting</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>Bypass Turnitin</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>Plagiarism-free outputs</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>Advanced Mode</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={CheckOrange} alt="" />
                                                            </Box>
                                                            <Typography>API</Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid2>
                                            <Grid2 size={{ xs: 12, md: 4 }}>
                                                <Box className="pricingPlanBox">
                                                    <Box className="pricingPlanBoxTop">
                                                        <Typography variant="h5">
                                                            Enterprise API
                                                        </Typography>

                                                    </Box>
                                                    <Box className="pricingPlanBoxMiddle">
                                                        <Typography variant="h3">
                                                            $20.00
                                                            <Typography>/yearly</Typography>
                                                        </Typography>
                                                        <Typography className="pricingPlanBoxMiddleText">Unlimited Words/year</Typography>
                                                        <Button>Try it now</Button>
                                                    </Box>
                                                    <Box className="pricingPlanBoxBottom">
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>
                                                                Powerful AI humanizer
                                                            </Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>Unlimited words per input</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>100% human content score</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>Undetectable rewriting</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>Bypass Turnitin</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>Plagiarism-free outputs</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>Advanced Mode</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>API</Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid2>
                                        </Grid2>
                                    </Container>
                                </TabPanel>
                                <TabPanel className="tabPanel" value={value} index={1}>
                                    <Container
                                        sx={{
                                            padding: "0",
                                        }}
                                    >
                                        <Grid2
                                            container
                                            spacing={{ xs: 2, md: 2, lg: 5 }}

                                        >
                                            <Grid2 size={{ xs: 12, md: 4 }}>
                                                <Box className="pricingPlanBox">
                                                    <Box className="pricingPlanBoxTop">
                                                        <Typography variant="h5">
                                                            Free
                                                        </Typography>

                                                    </Box>
                                                    <Box className="pricingPlanBoxMiddle">
                                                        <Typography variant="h3">
                                                            $ 0.00
                                                            <Typography>/monthly</Typography>
                                                        </Typography>
                                                        <Typography className="pricingPlanBoxMiddleText">250K Words/month</Typography>
                                                        <Button>Try it now</Button>
                                                    </Box>
                                                    <Box className="pricingPlanBoxBottom">
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>AI Humanization</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>Undetectable rewriting</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>500 words per input</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>Quick Support</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>Basic Mode</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={CheckOrange} alt="" />
                                                            </Box>
                                                            <Typography>Plagiarism-free outputs</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={CheckOrange} alt="" />
                                                            </Box>
                                                            <Typography>Advanced Mode</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={CheckOrange} alt="" />
                                                            </Box>
                                                            <Typography>API</Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid2>
                                            <Grid2 size={{ xs: 12, md: 4 }}>
                                                <Box className="pricingPlanBox pricingPlanBoxActive">
                                                    <Box className="pricingPlanBoxTop">
                                                        <Typography variant="h5">
                                                            Unlimited
                                                        </Typography>

                                                    </Box>
                                                    <Box className="pricingPlanBoxMiddle">
                                                        <Typography variant="h3">
                                                            $12.00
                                                            <Typography>/monthly</Typography>
                                                        </Typography>
                                                        <Typography className="pricingPlanBoxMiddleText">Unlimited Words/month</Typography>
                                                        <Button>Try it now</Button>
                                                    </Box>
                                                    <Box className="pricingPlanBoxBottom">
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>
                                                                Powerful AI humanizer
                                                            </Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>Unlimited words per input</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>100% human content score</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>Undetectable rewriting</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>Bypass Turnitin</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>Plagiarism-free outputs</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>Advanced Mode</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={CheckOrange} alt="" />
                                                            </Box>
                                                            <Typography>API</Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid2>
                                            <Grid2 size={{ xs: 12, md: 4 }}>
                                                <Box className="pricingPlanBox">
                                                    <Box className="pricingPlanBoxTop">
                                                        <Typography variant="h5">
                                                            Enterprise API
                                                        </Typography>

                                                    </Box>
                                                    <Box className="pricingPlanBoxMiddle">
                                                        <Typography variant="h3">
                                                            $29.00
                                                            <Typography>/monthly</Typography>
                                                        </Typography>
                                                        <Typography className="pricingPlanBoxMiddleText">Unlimited Words/month</Typography>
                                                        <Button>Try it now</Button>
                                                    </Box>
                                                    <Box className="pricingPlanBoxBottom">
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>
                                                                Powerful AI humanizer
                                                            </Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>Unlimited words per input</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>100% human content score</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>Undetectable rewriting</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>Bypass Turnitin</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>Plagiarism-free outputs</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>Advanced Mode</Typography>
                                                        </Box>
                                                        <Box className="pricingPlanBoxList">
                                                            <Box className="pricingPlanBoxListImage">
                                                                <img src={Checkgreen} alt="" />
                                                            </Box>
                                                            <Typography>API</Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid2>
                                        </Grid2>
                                    </Container>
                                </TabPanel>
                            </Box>
                        </Box>
                    </Grid2>
                </Grid2>
            </Container>
        </Box>
    )
}

export default PlanSection