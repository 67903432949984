import React from "react";
import {
  Container,
  Typography,
  Box,
  Grid2,
} from "@mui/material";

import Bypassui from "../../../assets/images/Bypassui.svg";
import aiDetectordor from "../../../assets/images/aiDetectordor.svg";
import CheckIcon from "../../../assets/icons/checkicon.svg";

const BypassSection = () => {
    return (
        <Box className="bypassSection">
            <Container className="customContainer">
                <Box className="aiDetectorTop">
                    <img className="aiDetectordorImage" src={aiDetectordor} alt="" />
                </Box>
                <Grid2 container spacing={4} alignItems="center">
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <Box className="aiDetectorLeft">
                            <img src={Bypassui} alt="" />
                        </Box>
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <Box className="aiDetector">
                            <Typography variant="h2">Bypass All AI Detectors</Typography>
                            <Typography>
                                Humanizing AI text saves time and improves readability,
                                credibility, and engagement.
                            </Typography>
                        </Box>
                        <Box className="bypassBoxWrap">
                            <Box className="bypassBox">
                                <Typography variant="h6">
                                    {" "}
                                    <img src={CheckIcon} />
                                    Improves Productivity
                                </Typography>
                                <Typography>
                                    Increases efficiency and output, reducing effort.
                                </Typography>
                            </Box>
                            <Box className="bypassBox">
                                <Typography variant="h6">
                                    <img src={CheckIcon} />
                                    Bypasses AI Detection
                                </Typography>
                                <Typography>
                                    Helps content avoid detection by AI systems.
                                </Typography>
                            </Box>
                            <Box className="bypassBox">
                                <Typography variant="h6">
                                    <img src={CheckIcon} />
                                    Provides Accurate Results
                                </Typography>
                                <Typography>
                                    Delivers precise and reliable outcomes consistently.
                                </Typography>
                            </Box>
                        </Box>
                    </Grid2>
                </Grid2>
            </Container>
        </Box>
    )
}

export default BypassSection