// src/App.js
import React from 'react';
import Button from '@mui/material/Button';
import Homepage from './pages/Homepage';
import 'typeface-inter';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import Home from "./ui/pages/Home/Home"

function App() {
  return (
    <>
      {/* <Homepage/> */}
      <Home />
    </>
  );
}

export default App;
