import React from "react";
import {
    Container,
    Typography,
    Box,
    Grid2,
} from "@mui/material";

import Language from "../../../assets/images/language.svg";

const LanguageSection = () => {
    return (
        <Box className="languageSection">
            <Container>
                <Grid2 container spacing={2} alignItems="center">
                    <Grid2 size={{ xs: 12, md: 12 }}>
                        <Box className="languageSectionTop">
                            <Typography>Our app can work in</Typography>
                            <Typography variant="h2">75+ Languages</Typography>
                        </Box>
                    </Grid2>
                </Grid2>
            </Container>
            <Container>
                <Grid2 container spacing={2} alignItems="center">
                    <Grid2 size={{ xs: 12, md: 12 }}>
                        <Box className="languageSectionBottom">
                            <img src={Language} alt="" />
                        </Box>
                    </Grid2>
                </Grid2>
            </Container>
        </Box>
    )
}

export default LanguageSection