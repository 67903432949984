import React from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  Grid2,
} from "@mui/material";

import ChallegeImage from "../../../assets/images/challegeimage.svg";

const ChallegeSection = () => {
    return (
        <Box className="challegeSection">
            <Container className="customContainer">
                <Grid2 container spacing={2} alignItems="center">
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <Box>
                            <Box className="challegeTop">
                                <Typography variant="h2">
                                    AI Content Challenges and Pitfalls
                                </Typography>
                                <Typography>
                                    AI content can be flagged by search engines, affecting
                                    rankings and credibility. It may also be detected by AI
                                    detectors.
                                </Typography>
                            </Box>
                            <Box className="challegeBottom">
                                <Typography variant="h6">
                                    What are the main challenges associated with AI-generated
                                    content?
                                </Typography>
                                <Typography>
                                    The main challenges of AI-generated content include lack of
                                    originality, potential inaccuracies, and risks of being
                                    flagged as low-quality, while struggling to capture human
                                    emotions and cultural context.
                                </Typography>
                                <Button className="getStart" variant="contained">
                                    Get Started
                                </Button>
                            </Box>
                        </Box>
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <Box className="ChallegeImage">
                            <img src={ChallegeImage} alt="" />
                        </Box>
                    </Grid2>
                </Grid2>
            </Container>
        </Box>
    )
}

export default ChallegeSection