import React, { useMemo, useState } from "react";
import {
  Container,
  Box,
  Grid2,
} from "@mui/material";

import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import AIbannerSection from "./AIbannerSection";
import ExpertSection from "./ExpertSection";
import BypassSection from "./BypassSection";
import ChallegeSection from "./ChallegeSection";
import HumanSection from "./HumanSection";
import PoweredSection from "./PoweredSection";
import PlanSection from "./PlanSection";
import LanguageSection from "./LanguageSection";
import FaqSection from "./FaqSection";
import UpcomingSection from "./UpcomingSection";
import EditorSection1 from "./EditorSection1";
import EditorSection2 from "./EditorSection2";
import "./home.css";



const Home = () => {


  const [textEditorContent, setTextEditorContent] = useState('');

  console.log("textEditorContent", textEditorContent);

  // const Editor1 = useMemo(() => <EditorSection1 key={0} setTextEditorContent={setTextEditorContent} />, [])
  // const Editor2 = useMemo(() => <EditorSection1 key={1} textEditorContent={textEditorContent} />, [])
  return (
    <>
      <Header />

      <AIbannerSection />

      {/* EditorSection */}

      <Box className="editorSection">
        <Container>
          <Box sx={{ flexGrow: 1 }} className="editorbox">
            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 12, md:6, }}>
                <EditorSection1 key={0} setTextEditorContent={setTextEditorContent} />
                {/* {Editor1} */}
              </Grid2>
              <Grid2 size={{ xs: 12, md:6, }}>
                <EditorSection2 key={textEditorContent} textEditorContent={textEditorContent} />
                {/* {Editor2} */}
              </Grid2>
            </Grid2>
          </Box>
        </Container>
      </Box>


      <ExpertSection />
      <BypassSection />
      <ChallegeSection />
      <HumanSection />
      <PoweredSection />
      <PlanSection />
      <LanguageSection />
      <FaqSection />
      <UpcomingSection />

      {/* <Box className="AIbannerSection">

        <Container className="customContainer">
          <Grid2 container alignItems="center">
            <Grid2 size={{ xs: 12 }}>
              <Box textAlign="center" className="AIbannerTopSection">
                <Typography variant="h1">
                  Most Accurate <Typography variant="span">Text Humanizer</Typography>,<br /> Transform AI Text Into Human
                  Content
                </Typography>
                <Typography>
                  Generate human-like, undetectable writing with Humanizery.
                  Plagiarism-free guaranteed!
                </Typography>
              </Box>
            </Grid2>
          </Grid2>
        </Container>
      </Box> */}
      {/* expert */}


      {/* <Box className="expertSection">
        <Container className="customContainer">
          <Grid2 container spacing={2} alignItems="center">
            <Grid2 size={{ xs: 12, md: 6 }}>
              <Typography variant="h2">Trusted by Experts & Users</Typography>
              <Typography>
                As user it is important to have positive experience while using
                the website
              </Typography>
              <Button className="getStart" variant="contained">
                Get Started
              </Button>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <Box className="brandWrap">
                <Button>
                  <img src={Google} />
                </Button>
                <Button>
                  <img src={Bing} />
                </Button>
                <Button>
                  <img src={Fiverr} />
                </Button>
                <Button className="marginLeftBrand">
                  <img src={Upwork} />
                </Button>
                <Button>
                  <img src={Quora} />
                </Button>
                <Button>
                  <img src={Yandex} />
                </Button>
                <Button>
                  <img src={Bai} />
                </Button>
                <Button>
                  <img src={Ask} />
                </Button>
                <Button>
                  <img src={Ecosia} />
                </Button>
              </Box>
            </Grid2>
          </Grid2>
        </Container>
      </Box> */}
      {/* expert end */}
      {/* bypass */}
      {/* <Box className="bypassSection">
        <Container className="customContainer">
          <Box className="aiDetectorTop">
            <img className="aiDetectordorImage" src={aiDetectordor} alt="" />
          </Box>
          <Grid2 container spacing={4} alignItems="center">
            <Grid2 size={{ xs: 12, md: 6 }}>
              <Box className="aiDetectorLeft">
                <img src={Bypassui} alt="" />
              </Box>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <Box className="aiDetector">
                <Typography variant="h2">Bypass All AI Detectors</Typography>
                <Typography>
                  Humanizing AI text saves time and improves readability,
                  credibility, and engagement.
                </Typography>
              </Box>
              <Box className="bypassBoxWrap">
                <Box className="bypassBox">
                  <Typography variant="h6">
                    {" "}
                    <img src={CheckIcon} />
                    Improves Productivity
                  </Typography>
                  <Typography>
                    Increases efficiency and output, reducing effort.
                  </Typography>
                </Box>
                <Box className="bypassBox">
                  <Typography variant="h6">
                    <img src={CheckIcon} />
                    Bypasses AI Detection
                  </Typography>
                  <Typography>
                    Helps content avoid detection by AI systems.
                  </Typography>
                </Box>
                <Box className="bypassBox">
                  <Typography variant="h6">
                    <img src={CheckIcon} />
                    Provides Accurate Results
                  </Typography>
                  <Typography>
                    Delivers precise and reliable outcomes consistently.
                  </Typography>
                </Box>
              </Box>
            </Grid2>
          </Grid2>
        </Container>
      </Box> */}
      {/* bypass end */}
      {/* challenge */}
      {/* <Box className="challegeSection">
        <Container className="customContainer">
          <Grid2 container spacing={2} alignItems="center">
            <Grid2 size={{ xs: 12, md: 6 }}>
              <Box>
                <Box className="challegeTop">
                  <Typography variant="h2">
                    AI Content Challenges and Pitfalls
                  </Typography>
                  <Typography>
                    AI content can be flagged by search engines, affecting
                    rankings and credibility. It may also be detected by AI
                    detectors.
                  </Typography>
                </Box>
                <Box className="challegeBottom">
                  <Typography variant="h6">
                    What are the main challenges associated with AI-generated
                    content?
                  </Typography>
                  <Typography>
                    The main challenges of AI-generated content include lack of
                    originality, potential inaccuracies, and risks of being
                    flagged as low-quality, while struggling to capture human
                    emotions and cultural context.
                  </Typography>
                  <Button className="getStart" variant="contained">
                    Get Started
                  </Button>
                </Box>
              </Box>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <Box className="ChallegeImage">
                <img src={ChallegeImage} alt="" />
              </Box>
            </Grid2>
          </Grid2>
        </Container>
      </Box> */}
      {/* challenge end */}
      {/* Human */}
      {/* <Box className="humanSection">
        <Box className="humanleftPattern">
          <img src={HumanleftPattern} alt="" />
        </Box>
        <Container className="customContainer">
          <Grid2 container spacing={2} alignItems="center">
            <Grid2 size={{ xs: 12, md: 12 }}>
              <Box className="humanSectionTop">
                <Typography variant="h2">
                  <Box className="humanLeft">
                    <img src={Humanleft} alt="" />
                  </Box>
                  Humanize AI content,
                  <br /> Get 100% human content
                </Typography>
                <Typography>
                  Human writing drives higher social media engagement and is
                  more shareable. It reduces reliance on writers and editors,
                  offering cost savings while maintaining authenticity.
                  <Box className="humanRight">
                    <img src={HumanRight} alt="" />
                  </Box>
                </Typography>
              </Box>
            </Grid2>
          </Grid2>
        </Container>
        <Container>
          <Grid2
            container
            spacing={2}
            alignItems="center"
            className="humanContent"
          >
            <Grid2 size={{ xs: 12, md: 4 }}>
              <Box className="humanIcon">
                <img src={Humanicon01} alt="" />
                <Typography>
                  Improves Content
                  <br /> Quality
                </Typography>
              </Box>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <Box className="humanIcon">
                <img src={Humanicon02} alt="" />
                <Typography>
                  Bypasses AI
                  <br /> Detection
                </Typography>
              </Box>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <Box className="humanIcon">
                <img src={Humanicon03} alt="" />
                <Typography>
                  Ensuring it’s polished and
                  <br /> undetectable.
                </Typography>
              </Box>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <Box className="humanIcon">
                <img src={Humanicon04} alt="" />
                <Typography>
                  Improve website
                  <br /> rankings and credibility
                </Typography>
              </Box>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <Box className="humanIcon">
                <img src={Humanicon05} alt="" />
                <Typography>
                  Enhance search engine
                  <br /> compatibility
                </Typography>
              </Box>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <Box className="humanIcon">
                <img src={Humanicon06} alt="" />
                <Typography>
                  Create more engaging, <br /> relatable, and clear content
                </Typography>
              </Box>
            </Grid2>
          </Grid2>
        </Container>
        <Box className="humanrightPattern">
          <img src={HumanrightPattern} alt="" />
        </Box>
      </Box> */}
      {/* Human end */}

      {/* poweredSection */}
      {/* <Box className="poweredSection">
        <Container className="customContainer">
          <Grid2 container spacing={4} alignItems="center">
            <Grid2 size={{ xs: 12, md: 6 }}>
              <Box className="poweredSectionTop">
                <Typography variant="h2">
                  Rank #1 with Stealthy AI-Powered Content
                </Typography>
                <Typography>
                  Advanced AI creates content that blends seamlessly with human
                  writing, avoiding detection by search engines.
                </Typography>
              </Box>
              <Box className="poweredSectionBottom">
                <Typography variant="h6">
                  Is AI-generated content safe to use for SEO?
                </Typography>
                <Typography>
                  AI content can be SEO-safe if it's high-quality, relevant, and
                  follows best practices. Undetectable AI content adheres to
                  search engine guidelines.
                </Typography>
              </Box>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <Box className="poweredLogocontent">
                <Box className="poweredLogo">
                  <img src={Powered01} alt="" />
                </Box>
                <Box className="poweredLogo borderRadiusOdd">
                  <img src={Powered02} alt="" />
                </Box>
                <Box className="poweredLogo">
                  <img src={Powered03} alt="" />
                </Box>
                <Box className="poweredLogo borderRadiusOdd">
                  <img src={Powered04} alt="" />
                </Box>
              </Box>
              <Box className="poweredRank">
                <img src={Rank} alt="" />
              </Box>
            </Grid2>
          </Grid2>
        </Container>
      </Box> */}
      {/* poweredSection end*/}
      {/* planSection*/}
      {/* <Box className="planSection">
        <Container>
          <Grid2 container spacing={2} alignItems="center">
            <Grid2 size={{ xs: 12, md: 12 }}>
              <Box className="planSectionTop">
                <Typography variant="h2">Plans and Pricing</Typography>
                <Typography>
                  Choose the right option for your work
                  <br /> and develop your project.
                </Typography>
              </Box>
            </Grid2>
          </Grid2>
        </Container>
        <Container className="customContainer">
          <Grid2 container spacing={2} alignItems="center">
            <Grid2 size={{ xs: 12, md: 12 }}>
              <Box className="planSectionBottom">
                <Box sx={{ width: "100%", position: "relative" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="pricingBox"
                    sx={{
                      "& .Mui-selected": {
                        color: "#fff",
                        background: "#1B8473",
                        "& h6.MuiTypography-root": {
                          color: "#fff",
                        },
                      },
                      "& .MuiTabs-indicator": {
                        display: "none",
                      },
                    }}
                  >
                    <Tab
                      label={
                        <Box className="pricingBoxWrap">
                          <Typography variant="h6">Yearly</Typography>{" "}
                          <Typography>Save 33%</Typography>
                        </Box>
                      }
                      id="tab-0"
                      aria-controls="tabpanel-0"
                    />
                    <Tab
                      label={
                        <Box className="pricingBoxWrap">
                          <Typography variant="h6">Monthly</Typography>
                        </Box>
                      }
                      id="tab-1"
                      aria-controls="tabpanel-1"
                    />
                  </Tabs>
                  <Box className="planSectionBottompattern">
                    <img src={Afterpricingplan} alt="" />
                  </Box>
                  <Box className="planSectionBottompatternLeft">
                    <img src={Afterpricingplan} alt="" />
                  </Box>
                  <TabPanel className="tabPanel" value={value} index={0}>
                    <Container
                      sx={{
                        padding: "0",
                      }}
                    >
                      <Grid2
                        container
                        spacing={{ xs: 2, md: 2, lg: 5 }}

                      >
                        <Grid2 size={{ xs: 12, md: 4 }}>
                          <Box className="pricingPlanBox">
                            <Box className="pricingPlanBoxTop">
                              <Typography variant="h5">
                                Free
                              </Typography>

                            </Box>
                            <Box className="pricingPlanBoxMiddle">
                              <Typography variant="h3">
                                $ 0.00
                                <Typography>/monthly</Typography>
                              </Typography>
                              <Typography className="pricingPlanBoxMiddleText">250K Words/month</Typography>
                              <Button>Try it now</Button>
                            </Box>
                            <Box className="pricingPlanBoxBottom">
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>AI Humanization</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>Undetectable rewriting</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>500 words per input</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>Quick Support</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>Basic Mode</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={CheckOrange} alt="" />
                                </Box>
                                <Typography>Plagiarism-free outputs</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={CheckOrange} alt="" />
                                </Box>
                                <Typography>Advanced Mode</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={CheckOrange} alt="" />
                                </Box>
                                <Typography>API</Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 4 }}>
                          <Box className="pricingPlanBox pricingPlanBoxActive">
                            <Box className="pricingPlanBoxTop">
                              <Typography variant="h5">
                                Unlimited
                              </Typography>

                            </Box>
                            <Box className="pricingPlanBoxMiddle">
                              <Typography variant="h3">
                                $9.00
                                <Typography>/monthly</Typography>
                              </Typography>
                              <Typography className="pricingPlanBoxMiddleText">Unlimited Words/month</Typography>
                              <Button>Try it now</Button>
                            </Box>
                            <Box className="pricingPlanBoxBottom">
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>
                                  Powerful AI humanizer
                                </Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>Unlimited words per input</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>100% human content score</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>Undetectable rewriting</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>Bypass Turnitin</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>Plagiarism-free outputs</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>Advanced Mode</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={CheckOrange} alt="" />
                                </Box>
                                <Typography>API</Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 4 }}>
                          <Box className="pricingPlanBox">
                            <Box className="pricingPlanBoxTop">
                              <Typography variant="h5">
                                Enterprise API
                              </Typography>

                            </Box>
                            <Box className="pricingPlanBoxMiddle">
                              <Typography variant="h3">
                                $29.00
                                <Typography>/monthly</Typography>
                              </Typography>
                              <Typography className="pricingPlanBoxMiddleText">Unlimited Words/month</Typography>
                              <Button>Try it now</Button>
                            </Box>
                            <Box className="pricingPlanBoxBottom">
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>
                                  Powerful AI humanizer
                                </Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>Unlimited words per input</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>100% human content score</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>Undetectable rewriting</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>Bypass Turnitin</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>Plagiarism-free outputs</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>Advanced Mode</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>API</Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Grid2>
                      </Grid2>
                    </Container>
                  </TabPanel>
                  <TabPanel className="tabPanel" value={value} index={1}>
                    <Container
                      sx={{
                        padding: "0",
                      }}
                    >
                      <Grid2
                        container
                        spacing={{ xs: 2, md: 2, lg: 5 }}

                      >
                        <Grid2 size={{ xs: 12, md: 4 }}>
                          <Box className="pricingPlanBox">
                            <Box className="pricingPlanBoxTop">
                              <Typography variant="h5">
                                Free
                              </Typography>

                            </Box>
                            <Box className="pricingPlanBoxMiddle">
                              <Typography variant="h3">
                                $ 0.00
                                <Typography>/monthly</Typography>
                              </Typography>
                              <Typography className="pricingPlanBoxMiddleText">250K Words/month</Typography>
                              <Button>Try it now</Button>
                            </Box>
                            <Box className="pricingPlanBoxBottom">
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>AI Humanization</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>Undetectable rewriting</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>500 words per input</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>Quick Support</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>Basic Mode</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={CheckOrange} alt="" />
                                </Box>
                                <Typography>Plagiarism-free outputs</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={CheckOrange} alt="" />
                                </Box>
                                <Typography>Advanced Mode</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={CheckOrange} alt="" />
                                </Box>
                                <Typography>API</Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 4 }}>
                          <Box className="pricingPlanBox pricingPlanBoxActive">
                            <Box className="pricingPlanBoxTop">
                              <Typography variant="h5">
                                Unlimited
                              </Typography>

                            </Box>
                            <Box className="pricingPlanBoxMiddle">
                              <Typography variant="h3">
                                $9.00
                                <Typography>/monthly</Typography>
                              </Typography>
                              <Typography className="pricingPlanBoxMiddleText">Unlimited Words/month</Typography>
                              <Button>Try it now</Button>
                            </Box>
                            <Box className="pricingPlanBoxBottom">
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>
                                  Powerful AI humanizer
                                </Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>Unlimited words per input</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>100% human content score</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>Undetectable rewriting</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>Bypass Turnitin</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>Plagiarism-free outputs</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>Advanced Mode</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={CheckOrange} alt="" />
                                </Box>
                                <Typography>API</Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 4 }}>
                          <Box className="pricingPlanBox">
                            <Box className="pricingPlanBoxTop">
                              <Typography variant="h5">
                                Enterprise API
                              </Typography>

                            </Box>
                            <Box className="pricingPlanBoxMiddle">
                              <Typography variant="h3">
                                $29.00
                                <Typography>/monthly</Typography>
                              </Typography>
                              <Typography className="pricingPlanBoxMiddleText">Unlimited Words/month</Typography>
                              <Button>Try it now</Button>
                            </Box>
                            <Box className="pricingPlanBoxBottom">
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>
                                  Powerful AI humanizer
                                </Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>Unlimited words per input</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>100% human content score</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>Undetectable rewriting</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>Bypass Turnitin</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>Plagiarism-free outputs</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>Advanced Mode</Typography>
                              </Box>
                              <Box className="pricingPlanBoxList">
                                <Box className="pricingPlanBoxListImage">
                                  <img src={Checkgreen} alt="" />
                                </Box>
                                <Typography>API</Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Grid2>
                      </Grid2>
                    </Container>
                  </TabPanel>
                </Box>
              </Box>
            </Grid2>
          </Grid2>
        </Container>
      </Box> */}
      {/* planSection end*/}

      {/* languageSection start*/}
      {/* <Box className="languageSection">
        <Container>
          <Grid2 container spacing={2} alignItems="center">
            <Grid2 size={{ xs: 12, md: 12 }}>
              <Box className="languageSectionTop">
                <Typography>Our app can work in</Typography>
                <Typography variant="h2">75+ Languages</Typography>
              </Box>
            </Grid2>
          </Grid2>
        </Container>
        <Container>
          <Grid2 container spacing={2} alignItems="center">
            <Grid2 size={{ xs: 12, md: 12 }}>
              <Box className="languageSectionBottom">
                <img src={Language} alt="" />
              </Box>
            </Grid2>
          </Grid2>
        </Container>
      </Box> */}
      {/* languageSection end*/}
      {/* faqSection start*/}
      {/* <Box className="faqSection">
        <Container>
          <Grid2 container spacing={2} alignItems="center">
            <Grid2 size={{ xs: 12, md: 12 }}>
              <Box className="faqSectionTop">
                <Typography variant="h2">
                  Frequently asked questioned
                </Typography>
                <Typography>
                  Boost productivity with Saasify—collaborate, manage
                  <br /> projects, and achieve more from any workspace!
                </Typography>
              </Box>
            </Grid2>
          </Grid2>
        </Container>
        <Container>
          <Grid2 container>
            <Grid2 size={{ xs: 12, md: 12 }}>
              <Box
                className="faqSectionBottom"
                sx={{
                  "& .MuiAccordion-root.Mui-expanded": {
                    background: "#1B8473",
                    "& .faqSectionTitle": {
                      color: "#fff",
                    },
                    "& .faqSectionContent": {
                      color: "#fff",
                    },
                    "& img": {
                      opacity: "0",
                    },
                  },
                }}
              >
                <Accordion
                  sx={{
                    minHeight: "90px",
                    background: "#F6F6F6",
                    borderRadius: "13.28px !important",
                    padding: { md: "17px 30px", xs: "10px" },
                    boxSizing: "border-box",
                    cursor: "pointer",
                    boxShadow: "none",
                  }}
                >
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className="faqSectionTitle">
                      Is the Humanizer AI Text Tool Free?
                    </Typography>
                    <Box
                      sx={{
                        marginLeft: "auto",
                      }}
                    >
                      <img src={LongSquare} alt="" />
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="faqSectionContent">
                      Numquam eius modi tempora incidunt ut labore et dolore
                      magnam aliquam quaerat voluptatem. Ut enim ad minima
                      veniam, quis nostrum exercitationem ullam corporis lorem
                      ipsum dolor sit amet. There are many variations of
                      passages of Lorem Ipsum available.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  sx={{
                    minHeight: "90px",
                    background: "#F6F6F6",
                    borderRadius: "13.28px !important",
                    padding: { md: "17px 30px", xs: "10px" },
                    boxSizing: "border-box",
                    cursor: "pointer",
                    boxShadow: "none",
                  }}
                >
                  <AccordionSummary
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className="faqSectionTitle">
                      Can this Humanize AI Tool Produce Fully Human Text?
                    </Typography>
                    <Box
                      sx={{
                        marginLeft: "auto",
                      }}
                    >
                      <img src={LongSquare} alt="" />
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="faqSectionContent">
                      Numquam eius modi tempora incidunt ut labore et dolore
                      magnam aliquam quaerat voluptatem. Ut enim ad minima
                      veniam, quis nostrum exercitationem ullam corporis lorem
                      ipsum dolor sit amet. There are many variations of
                      passages of Lorem Ipsum available.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    minHeight: "90px",
                    background: "#F6F6F6",
                    borderRadius: "13.28px !important",
                    padding: { md: "17px 30px", xs: "10px" },
                    boxSizing: "border-box",
                    cursor: "pointer",
                    boxShadow: "none",
                  }}
                >
                  <AccordionSummary
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                  >
                    <Typography className="faqSectionTitle">
                      Benefits of the Humanize AI Text Tool?
                    </Typography>
                    <Box
                      sx={{
                        marginLeft: "auto",
                      }}
                    >
                      <img src={LongSquare} alt="" />
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="faqSectionContent">
                      Numquam eius modi tempora incidunt ut labore et dolore
                      magnam aliquam quaerat voluptatem. Ut enim ad minima
                      veniam, quis nostrum exercitationem ullam corporis lorem
                      ipsum dolor sit amet. There are many variations of
                      passages of Lorem Ipsum available.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    minHeight: "90px",
                    background: "#F6F6F6",
                    borderRadius: "13.28px !important",
                    padding: { md: "17px 30px", xs: "10px" },
                    boxSizing: "border-box",
                    cursor: "pointer",
                    boxShadow: "none",
                  }}
                >
                  <AccordionSummary
                    aria-controls="panel4a-content"
                    id="panel4a-header"
                  >
                    <Typography className="faqSectionTitle">
                      How does the Humanize AI Text Algorithm work?
                    </Typography>
                    <Box
                      sx={{
                        marginLeft: "auto",
                      }}
                    >
                      <img src={LongSquare} alt="" />
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="faqSectionContent">
                      Numquam eius modi tempora incidunt ut labore et dolore
                      magnam aliquam quaerat voluptatem. Ut enim ad minima
                      veniam, quis nostrum exercitationem ullam corporis lorem
                      ipsum dolor sit amet. There are many variations of
                      passages of Lorem Ipsum available.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    minHeight: "90px",
                    background: "#F6F6F6",
                    borderRadius: "13.28px !important",
                    padding: { md: "17px 30px", xs: "10px" },
                    boxSizing: "border-box",
                    cursor: "pointer",
                    boxShadow: "none",
                  }}
                >
                  <AccordionSummary
                    aria-controls="panel4a-content"
                    id="panel4a-header"
                  >
                    <Typography className="faqSectionTitle">
                      Is the SEO value of my content retained?
                    </Typography>
                    <Box
                      sx={{
                        marginLeft: "auto",
                      }}
                    >
                      <img src={LongSquare} alt="" />
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="faqSectionContent">
                      Numquam eius modi tempora incidunt ut labore et dolore
                      magnam aliquam quaerat voluptatem. Ut enim ad minima
                      veniam, quis nostrum exercitationem ullam corporis lorem
                      ipsum dolor sit amet. There are many variations of
                      passages of Lorem Ipsum available.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    minHeight: "90px",
                    background: "#F6F6F6",
                    borderRadius: "13.28px !important",
                    padding: { md: "17px 30px", xs: "10px" },
                    boxSizing: "border-box",
                    cursor: "pointer",
                    boxShadow: "none",
                  }}
                >
                  <AccordionSummary
                    aria-controls="panel4a-content"
                    id="panel4a-header"
                  >
                    <Typography className="faqSectionTitle">
                      Does our AI Text Converter bypass all the AI detectors?
                    </Typography>
                    <Box
                      sx={{
                        marginLeft: "auto",
                      }}
                    >
                      <img src={LongSquare} alt="" />
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="faqSectionContent">
                      Numquam eius modi tempora incidunt ut labore et dolore
                      magnam aliquam quaerat voluptatem. Ut enim ad minima
                      veniam, quis nostrum exercitationem ullam corporis lorem
                      ipsum dolor sit amet. There are many variations of
                      passages of Lorem Ipsum available.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    minHeight: "90px",
                    background: "#F6F6F6",
                    borderRadius: "13.28px !important",
                    padding: { md: "17px 30px", xs: "10px" },
                    boxSizing: "border-box",
                    cursor: "pointer",
                    boxShadow: "none",
                  }}
                >
                  <AccordionSummary
                    aria-controls="panel4a-content"
                    id="panel4a-header"
                  >
                    <Typography className="faqSectionTitle">
                      Can this Humanize AI Tool Produce Fully Human Text?
                    </Typography>
                    <Box
                      sx={{
                        marginLeft: "auto",
                      }}
                    >
                      <img src={LongSquare} alt="" />
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="faqSectionContent">
                      Numquam eius modi tempora incidunt ut labore et dolore
                      magnam aliquam quaerat voluptatem. Ut enim ad minima
                      veniam, quis nostrum exercitationem ullam corporis lorem
                      ipsum dolor sit amet. There are many variations of
                      passages of Lorem Ipsum available.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Grid2>
          </Grid2>
        </Container>
      </Box> */}
      {/* faqSection end*/}

      {/* upcoming start*/}
      {/* <Box className="upcomingSection">
        <Container className="customContainer">
          <Grid2 container spacing={7} alignItems="center">
            <Grid2 size={{ xs: 12, md: 4 }}>
              <Box className="upcomingSectionLeft">
                <Typography variant="h2">Upcoming Ai tools</Typography>
                <Typography>
                  Explore the other powerful AI tools from the Humanizery Free
                  Tool Suite
                </Typography>
              </Box>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 8 }} sx={{ position: "relative" }}>
              <Box className="upcomingSectionbefore">
                <img src={Afterpricingplan} alt="" />
              </Box>
              <Box className="upcomingSectionRight">
                <Box className="upcomingAiCard">
                  <Typography variant="h5">Ai Inter-linker</Typography>
                  <Typography>
                    AI Internal-Linker automatically creates relevant internal
                    links, enhancing site navigation and SEO.
                  </Typography>
                  <Button>
                    Learn more
                    <Box
                      sx={{
                        lineHeight: "0",
                      }}
                    >
                      <img src={ArrowRight} alt="" />
                    </Box>
                  </Button>
                </Box>
                <Box className="upcomingAiCard">
                  <Typography variant="h5">AI WP Scheduler</Typography>
                  <Typography>
                    AI WP Scheduler automates WordPress content scheduling,
                    streamlining your publishing process and saving time.
                  </Typography>
                  <Button>
                    Learn more
                    <Box
                      sx={{
                        lineHeight: "0",
                      }}
                    >
                      <img src={ArrowRight} alt="" />
                    </Box>
                  </Button>
                </Box>
                <Box className="upcomingAiCard">
                  <Typography variant="h5">Integrations</Typography>
                  <Typography>
                    Integrations connect tools to streamline workflows and boost
                    productivity.
                  </Typography>
                  <Button>
                    Learn more
                    <Box
                      sx={{
                        lineHeight: "0",
                      }}
                    >
                      <img src={ArrowRight} alt="" />
                    </Box>
                  </Button>
                </Box>
                <Box className="upcomingAiCard">
                  <Typography variant="h5">API</Typography>
                  <Typography>
                    An API AI tool integrates AI features into apps for enhanced
                    functionality.
                  </Typography>
                  <Button>
                    Learn more
                    <Box
                      sx={{
                        lineHeight: "0",
                      }}
                    >
                      <img src={ArrowRight} alt="" />
                    </Box>
                  </Button>
                </Box>
              </Box>
              <Box className="upcomingSectionafter">
                <img src={Afterpricingplan} alt="" />
              </Box>
            </Grid2>
          </Grid2>
        </Container>
      </Box> */}
      {/* upcoming end*/}
      <Footer />
    </>
  );
};

export default Home;



