import React, { useEffect, useState } from 'react';
import { Modal, Button, TextField, Box } from '@mui/material';

const SourceModal = ({ open, toggle, handleSave, content, setEditorContent }) => {
    const [sourceContent, setSourceContent] = useState('');

    console.log("sourceContent", sourceContent);

    useEffect(() => {
        setSourceContent(content);
    }, [content])

    const handleChange = (event) => {
        setSourceContent(event.target.value);
        setEditorContent(event.target.value)
    };

    const handleSaveClick = () => {
        handleSave(sourceContent);
    };

    return (
        <Modal
            open={open}
            onClose={toggle}
        // aria-labelledby="source-modal-title"
        // aria-describedby="source-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <h2 id="source-modal-title">Edit Source</h2>
                <TextField
                    id="source-content"
                    label="Source Content"
                    multiline
                    rows={10}
                    variant="outlined"
                    fullWidth
                    value={sourceContent}
                    onChange={handleChange}
                />
                <Box mt={2} sx={{ textAlign: 'right' }}>
                    <Button onClick={handleSaveClick} variant="contained" color="primary">
                        Save
                    </Button>
                    <Button onClick={toggle} variant="outlined" color="secondary" sx={{ ml: 2 }}>
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default SourceModal;
