import React, { useState } from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  Grid2,
  AccordionDetails,
  Accordion,
  AccordionSummary,
} from "@mui/material";

import Google from "../../../assets/images/Google.svg";
import Bing from "../../../assets/images/Bing.svg";
import Fiverr from "../../../assets/images/Fiverr.svg";
import Upwork from "../../../assets/images/Upwork.svg";
import Quora from "../../../assets/images/Quora.svg";
import Yandex from "../../../assets/images/Yandex.svg";
import Bai from "../../../assets/images/Bai.svg";
import Ask from "../../../assets/images/Ask.svg";
import Ecosia from "../../../assets/images/Ecosia.svg";
import Bypassui from "../../../assets/images/Bypassui.svg";
import aiDetectordor from "../../../assets/images/aiDetectordor.svg";
import CheckIcon from "../../../assets/icons/checkicon.svg";
import Checkgreen from "../../../assets/icons/checkgreen.svg";
import CheckOrange from "../../../assets/icons/checkorange.svg";
import Humanicon01 from "../../../assets/icons/humanicon01.svg";
import Humanicon02 from "../../../assets/icons/humanicon02.svg";
import Humanicon03 from "../../../assets/icons/humanicon03.svg";
import Humanicon04 from "../../../assets/icons/humanicon04.svg";
import Humanicon05 from "../../../assets/icons/humanicon05.svg";
import Humanicon06 from "../../../assets/icons/humanicon06.svg";
import LongSquare from "../../../assets/icons/longsquare.svg";
import ArrowRight from "../../../assets/icons/rightarrow.svg";
import ChallegeImage from "../../../assets/images/challegeimage.svg";
import Afterpricingplan from "../../../assets/images/afterpricingplan.svg";
import Powered01 from "../../../assets/images/powered01.svg";
import Powered02 from "../../../assets/images/powered02.svg";
import Powered03 from "../../../assets/images/powered03.svg";
import Powered04 from "../../../assets/images/powered04.svg";
import HumanleftPattern from "../../../assets/images/humanLeftPatterns.svg";
import HumanrightPattern from "../../../assets/images/humanRightPatterns.svg";
import Humanleft from "../../../assets/images/humanLeft.svg";
import HumanRight from "../../../assets/images/humanRight.svg";
import Rank from "../../../assets/images/rank.svg";
import Language from "../../../assets/images/language.svg";
import { Tabs, Tab } from "@mui/material";

const ExpertSection = () => {
    return (
        <Box className="expertSection">
            <Container className="customContainer">
                <Grid2 container spacing={2} alignItems="center">
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <Typography variant="h2">Trusted by Experts & Users</Typography>
                        <Typography>
                            As user it is important to have positive experience while using
                            the website
                        </Typography>
                        <Button className="getStart" variant="contained">
                            Get Started
                        </Button>
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <Box className="brandWrap">
                            <Button>
                                <img src={Google} />
                            </Button>
                            <Button>
                                <img src={Bing} />
                            </Button>
                            <Button>
                                <img src={Fiverr} />
                            </Button>
                            <Button className="marginLeftBrand">
                                <img src={Upwork} />
                            </Button>
                            <Button>
                                <img src={Quora} />
                            </Button>
                            <Button>
                                <img src={Yandex} />
                            </Button>
                            <Button>
                                <img src={Bai} />
                            </Button>
                            <Button>
                                <img src={Ask} />
                            </Button>
                            <Button>
                                <img src={Ecosia} />
                            </Button>
                        </Box>
                    </Grid2>
                </Grid2>
            </Container>
        </Box>
    )
}

export default ExpertSection