import React from "react";
import {
    Container,
    Typography,
    Button,
    Box,
    Grid2,
} from "@mui/material";

import "./footer.css";
import Logo from "../../../assets/images/humanizerylogo.svg";

import Facebook from "../../../assets/icons/facebook.svg";

import TelegramIcon from "../../../assets/icons/telegram.svg";
import GitIcon from "../../../assets/icons/git.svg";
import InstagramIcon from "../../../assets/icons/instagram.svg";
import Catching from "../../../assets/icons/cat.svg";



const Footer = () => {
    return (
        <>
            {/* footer */}
            <Box className="footerSection">
                <Box className="footerTop">
                    <Container className="customContainer">
                        <Grid2 container spacing={2} alignItems="center">
                            <Grid2 size={{ xs: 12, md: 12 }}>
                                <Button
                                    sx={{
                                        padding: "0",
                                        "& img": {
                                            maxWidth: "74px",
                                            width: "100%",
                                        },
                                    }}
                                >
                                    <img src={Logo} alt="" />
                                </Button>
                            </Grid2>
                        </Grid2>
                    </Container>
                </Box>
                <Box className="footerMiddle">
                    <Container className="customContainer">
                        <Grid2 container spacing={2} alignItems="flex-start">
                            <Grid2 size={{ xs: 12, sm: 6, md: 6, lg: 3 }}>
                                <Box className="footerColumn">
                                    <Typography variant="h5">Product</Typography>
                                    <Box className="footerColumnList">
                                        <Typography>AI Humanizer</Typography>
                                        <Typography>AI Inter-linker</Typography>
                                        <Typography>AI WP Scheduler</Typography>
                                        <Typography>Integrations</Typography>
                                        <Typography>API</Typography>
                                    </Box>
                                </Box>
                            </Grid2>
                            <Grid2 size={{ xs: 12, sm: 6, md: 6, lg: 3 }}>
                                <Box className="footerColumn">
                                    <Typography variant="h5">Use Cases</Typography>
                                    <Box className="footerColumnList">
                                        <Typography>Web-designers</Typography>
                                        <Typography>HR Professionals</Typography>
                                        <Typography>Small Business</Typography>
                                        <Typography>Website Builder</Typography>
                                    </Box>
                                </Box>
                            </Grid2>
                            <Grid2 size={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
                                <Box className="footerColumn">
                                    <Typography variant="h5">Use Cases</Typography>
                                    <Box className="footerColumnList">
                                        <Typography>PR Professionals</Typography>
                                        <Typography>Social Media Marketers</Typography>
                                        <Typography>Bloggers</Typography>
                                        <Typography>Freelance Writers</Typography>
                                    </Box>
                                </Box>
                            </Grid2>
                            <Grid2 size={{ xs: 12, sm: 6, md: 6, lg: 2 }}>
                                <Box className="footerColumn">
                                    <Typography variant="h5">Company</Typography>
                                    <Box className="footerColumnList">
                                        <Typography>About Us</Typography>
                                        <Typography>Careers</Typography>
                                        <Typography>FAQs</Typography>
                                        <Typography>Teams</Typography>
                                        <Typography>Contact Us</Typography>
                                    </Box>
                                </Box>
                            </Grid2>
                        </Grid2>
                    </Container>
                </Box>
                <Box className="footerBottom">
                    <Container className="customContainer">
                        <Grid2 container spacing={2} alignItems="center">
                            <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 6.5 }}>
                                <Box className="footerBottomlink">
                                    <Typography>Privacy Policy</Typography>
                                    <Typography>Terms of Use</Typography>
                                    <Typography>Sales and Refunds</Typography>
                                    <Typography>Legal</Typography>
                                    <Typography>Site Map</Typography>
                                </Box>
                            </Grid2>
                            <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 3 }}>
                                <Box className="footerBottomSocial">
                                    <Button>
                                        <img src={Facebook} alt="" />
                                    </Button>
                                    <Button>
                                        <img src={TelegramIcon} alt="" />
                                    </Button>
                                    <Button>
                                        <img src={GitIcon} alt="" />
                                    </Button>
                                    <Button>
                                        <img src={InstagramIcon} alt="" />
                                    </Button>
                                    <Button>
                                        <img src={Catching} alt="" />
                                    </Button>
                                </Box>
                            </Grid2>
                            <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 2.5 }}>
                                <Box className="footerBottomcopyright">
                                    <Typography>© 2024 All Rights Reserved</Typography>
                                </Box>
                            </Grid2>
                        </Grid2>
                    </Container>
                </Box>
            </Box>
            {/* footer end*/}
        </>
    );
};

export default Footer;
