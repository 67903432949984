import React from "react";
import {
  Container,
  Typography,
  Box,
  Grid2,
  AccordionDetails,
  Accordion,
  AccordionSummary,
} from "@mui/material";
import LongSquare from "../../../assets/icons/longsquare.svg";



const FaqSection = () => {
    return (
        <Box className="faqSection">
            <Container>
                <Grid2 container spacing={2} alignItems="center">
                    <Grid2 size={{ xs: 12, md: 12 }}>
                        <Box className="faqSectionTop">
                            <Typography variant="h2">
                                Frequently asked questioned
                            </Typography>
                            <Typography>
                                Boost productivity with Saasify—collaborate, manage
                                <br /> projects, and achieve more from any workspace!
                            </Typography>
                        </Box>
                    </Grid2>
                </Grid2>
            </Container>
            <Container>
                <Grid2 container>
                    <Grid2 size={{ xs: 12, md: 12 }}>
                        <Box
                            className="faqSectionBottom"
                            sx={{
                                "& .MuiAccordion-root.Mui-expanded": {
                                    background: "#1B8473",
                                    "& .faqSectionTitle": {
                                        color: "#fff",
                                    },
                                    "& .faqSectionContent": {
                                        color: "#fff",
                                    },
                                    "& img": {
                                        opacity: "0",
                                    },
                                },
                            }}
                        >
                            <Accordion
                                sx={{
                                    minHeight: "90px",
                                    background: "#F6F6F6",
                                    borderRadius: "13.28px !important",
                                    padding: { md: "17px 30px", xs: "10px" },
                                    boxSizing: "border-box",
                                    cursor: "pointer",
                                    boxShadow: "none",
                                }}
                            >
                                <AccordionSummary
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className="faqSectionTitle">
                                        Is the Humanizer AI Text Tool Free?
                                    </Typography>
                                    <Box
                                        sx={{
                                            marginLeft: "auto",
                                        }}
                                    >
                                        <img src={LongSquare} alt="" />
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faqSectionContent">
                                        Numquam eius modi tempora incidunt ut labore et dolore
                                        magnam aliquam quaerat voluptatem. Ut enim ad minima
                                        veniam, quis nostrum exercitationem ullam corporis lorem
                                        ipsum dolor sit amet. There are many variations of
                                        passages of Lorem Ipsum available.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion
                                sx={{
                                    minHeight: "90px",
                                    background: "#F6F6F6",
                                    borderRadius: "13.28px !important",
                                    padding: { md: "17px 30px", xs: "10px" },
                                    boxSizing: "border-box",
                                    cursor: "pointer",
                                    boxShadow: "none",
                                }}
                            >
                                <AccordionSummary
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography className="faqSectionTitle">
                                        Can this Humanize AI Tool Produce Fully Human Text?
                                    </Typography>
                                    <Box
                                        sx={{
                                            marginLeft: "auto",
                                        }}
                                    >
                                        <img src={LongSquare} alt="" />
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faqSectionContent">
                                        Numquam eius modi tempora incidunt ut labore et dolore
                                        magnam aliquam quaerat voluptatem. Ut enim ad minima
                                        veniam, quis nostrum exercitationem ullam corporis lorem
                                        ipsum dolor sit amet. There are many variations of
                                        passages of Lorem Ipsum available.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    minHeight: "90px",
                                    background: "#F6F6F6",
                                    borderRadius: "13.28px !important",
                                    padding: { md: "17px 30px", xs: "10px" },
                                    boxSizing: "border-box",
                                    cursor: "pointer",
                                    boxShadow: "none",
                                }}
                            >
                                <AccordionSummary
                                    aria-controls="panel3a-content"
                                    id="panel3a-header"
                                >
                                    <Typography className="faqSectionTitle">
                                        Benefits of the Humanize AI Text Tool?
                                    </Typography>
                                    <Box
                                        sx={{
                                            marginLeft: "auto",
                                        }}
                                    >
                                        <img src={LongSquare} alt="" />
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faqSectionContent">
                                        Numquam eius modi tempora incidunt ut labore et dolore
                                        magnam aliquam quaerat voluptatem. Ut enim ad minima
                                        veniam, quis nostrum exercitationem ullam corporis lorem
                                        ipsum dolor sit amet. There are many variations of
                                        passages of Lorem Ipsum available.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    minHeight: "90px",
                                    background: "#F6F6F6",
                                    borderRadius: "13.28px !important",
                                    padding: { md: "17px 30px", xs: "10px" },
                                    boxSizing: "border-box",
                                    cursor: "pointer",
                                    boxShadow: "none",
                                }}
                            >
                                <AccordionSummary
                                    aria-controls="panel4a-content"
                                    id="panel4a-header"
                                >
                                    <Typography className="faqSectionTitle">
                                        How does the Humanize AI Text Algorithm work?
                                    </Typography>
                                    <Box
                                        sx={{
                                            marginLeft: "auto",
                                        }}
                                    >
                                        <img src={LongSquare} alt="" />
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faqSectionContent">
                                        Numquam eius modi tempora incidunt ut labore et dolore
                                        magnam aliquam quaerat voluptatem. Ut enim ad minima
                                        veniam, quis nostrum exercitationem ullam corporis lorem
                                        ipsum dolor sit amet. There are many variations of
                                        passages of Lorem Ipsum available.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    minHeight: "90px",
                                    background: "#F6F6F6",
                                    borderRadius: "13.28px !important",
                                    padding: { md: "17px 30px", xs: "10px" },
                                    boxSizing: "border-box",
                                    cursor: "pointer",
                                    boxShadow: "none",
                                }}
                            >
                                <AccordionSummary
                                    aria-controls="panel4a-content"
                                    id="panel4a-header"
                                >
                                    <Typography className="faqSectionTitle">
                                        Is the SEO value of my content retained?
                                    </Typography>
                                    <Box
                                        sx={{
                                            marginLeft: "auto",
                                        }}
                                    >
                                        <img src={LongSquare} alt="" />
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faqSectionContent">
                                        Numquam eius modi tempora incidunt ut labore et dolore
                                        magnam aliquam quaerat voluptatem. Ut enim ad minima
                                        veniam, quis nostrum exercitationem ullam corporis lorem
                                        ipsum dolor sit amet. There are many variations of
                                        passages of Lorem Ipsum available.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    minHeight: "90px",
                                    background: "#F6F6F6",
                                    borderRadius: "13.28px !important",
                                    padding: { md: "17px 30px", xs: "10px" },
                                    boxSizing: "border-box",
                                    cursor: "pointer",
                                    boxShadow: "none",
                                }}
                            >
                                <AccordionSummary
                                    aria-controls="panel4a-content"
                                    id="panel4a-header"
                                >
                                    <Typography className="faqSectionTitle">
                                        Does our AI Text Converter bypass all the AI detectors?
                                    </Typography>
                                    <Box
                                        sx={{
                                            marginLeft: "auto",
                                        }}
                                    >
                                        <img src={LongSquare} alt="" />
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faqSectionContent">
                                        Numquam eius modi tempora incidunt ut labore et dolore
                                        magnam aliquam quaerat voluptatem. Ut enim ad minima
                                        veniam, quis nostrum exercitationem ullam corporis lorem
                                        ipsum dolor sit amet. There are many variations of
                                        passages of Lorem Ipsum available.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    minHeight: "90px",
                                    background: "#F6F6F6",
                                    borderRadius: "13.28px !important",
                                    padding: { md: "17px 30px", xs: "10px" },
                                    boxSizing: "border-box",
                                    cursor: "pointer",
                                    boxShadow: "none",
                                }}
                            >
                                <AccordionSummary
                                    aria-controls="panel4a-content"
                                    id="panel4a-header"
                                >
                                    <Typography className="faqSectionTitle">
                                        Can this Humanize AI Tool Produce Fully Human Text?
                                    </Typography>
                                    <Box
                                        sx={{
                                            marginLeft: "auto",
                                        }}
                                    >
                                        <img src={LongSquare} alt="" />
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faqSectionContent">
                                        Numquam eius modi tempora incidunt ut labore et dolore
                                        magnam aliquam quaerat voluptatem. Ut enim ad minima
                                        veniam, quis nostrum exercitationem ullam corporis lorem
                                        ipsum dolor sit amet. There are many variations of
                                        passages of Lorem Ipsum available.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </Grid2>
                </Grid2>
            </Container>
        </Box>
    )
}

export default FaqSection