import React from "react";
import {
  Container,
  Typography,
  Box,
  Grid2,
} from "@mui/material";

const AIbannerSection = () => {
  return (
    <>    <Box className="AIbannerSection">
      <Container className="customContainer">
        <Grid2 container alignItems="center">
          <Grid2 size={{ xs: 12 }}>
            <Box textAlign="center" className="AIbannerTopSection">
              <Typography variant="h1">
                Most Accurate <Typography variant="span">Text Humanizer</Typography>,<br /> Transform AI Text Into Human
                Content
              </Typography>
              <Typography>
                Generate human-like, undetectable writing with Humanizery.
                Plagiarism-free guaranteed!
              </Typography>
            </Box>
          </Grid2>
        </Grid2>
      </Container>
    </Box></>
  )
}

export default AIbannerSection