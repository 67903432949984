import React, { useState } from 'react'
import {
    Container,
    Button,
    Box,
    Grid2,
    IconButton,
    Drawer,
    useMediaQuery,
    List, ListItem, ListItemText,
} from "@mui/material";


import Logo from "../../../assets/images/humanizerylogo.svg";
import MenuIcon from "@mui/icons-material/Menu";
import "./header.css"


export default function Header() {

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const menuItems = ["Home", "About Us", "Pricing", "FAQ"];
    const drawerList = () => (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                {menuItems.map((text, index) => (
                    <ListItem button key={index}>
                        <ListItemText primary={text} />
                    </ListItem>
                ))}
            </List>
            <Box
                className="headerMenuRight"
                sx={{ padding: "0 16px", display: "flex", flexDirection: "column" }}
            >
                <Button className="signButton">Sign up</Button>
                <Button className="loginButton">Login</Button>
            </Box>
        </Box>
    );
    const toggleDrawer = (open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setIsDrawerOpen(open);
    };


    return (
        <>   <Box className="header">
            <Container className="customContainer">
                <Grid2 container spacing={2} className="headerContent">
                    <Grid2 xs={12} md={3}>
                        <Box sx={{ cursor: "pointer" }}>
                            <img src={Logo} alt="Logo" />
                        </Box>
                    </Grid2>

                    {/* Conditional rendering based on screen size */}
                    {isMobile ? (
                        <Grid2 xs={12} md={9}>
                            <Box sx={{ textAlign: "right" }}>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    onClick={toggleDrawer(true)}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Box>
                            <Drawer
                                anchor="right"
                                open={isDrawerOpen}
                                onClose={toggleDrawer(false)}
                            >
                                {drawerList()}
                            </Drawer>
                        </Grid2>
                    ) : (
                        <>
                            <Grid2 xs={12} md={6}>
                                <Box className="headerMenu">
                                    {menuItems.map((item, index) => (
                                        <Button key={index}>{item}</Button>
                                    ))}
                                </Box>
                            </Grid2>
                            <Grid2 xs={12} md={3}>
                                <Box className="headerMenuRight">
                                    <Button className="signButton">Sign up</Button>
                                    <Button className="loginButton">Login</Button>
                                </Box>
                            </Grid2>
                        </>
                    )}
                </Grid2>
            </Container>
        </Box>
        </>
    )
}
