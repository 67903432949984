import React, { useEffect, useMemo, useRef, useState } from 'react';
import 'quill/dist/quill.snow.css';
import ReactQuill, { Quill } from 'react-quill';
import SourceModal from './SourceModal';
import { Box, IconButton } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
// Create a custom blot for the divider
const DividerBlot = Quill.import('blots/block');

class Divider extends DividerBlot {
    static create() {
        let node = super.create();
        node.classList.add('ql-divider');
        return node;
    }

    static formats(node) {
        return true;
    }

    format(name, value) {
        if (name === 'divider') {
            this.domNode.classList.toggle('ql-divider', value);
        } else {
            super.format(name, value);
        }
    }
}

Divider.blotName = 'divider';
Divider.tagName = 'hr';

Quill.register(Divider);

const toolbarOptions = [
    ["undo", "redo"],
    [{ size: ["small", false, "large", "huge"] }],
    [{ align: [] }],
    [{ color: [] }],
    ["bold", "italic", "underline", "strike", "source"],
    [{ list: "bullet" }, { list: "ordered" }],
    ["link", "image", "code-block", "blockquote", "divider"]
];


const EditorSection1 = ({ setTextEditorContent }) => {

    const [modalOpen, setModalOpen] = useState(false);
    const [editorContent, setEditorContent] = useState('');

    const quillRef = useRef(null);

    useEffect(() => {
        let undoBtn = document.getElementsByClassName('ql-undo')[0];
        if (undoBtn) undoBtn.innerHTML = "&#10094;&#10094;";
        let redoBtn = document.getElementsByClassName('ql-redo')[0];
        if (redoBtn) redoBtn.innerHTML = "&#10095;&#10095;";
        let dividerBtn = document.getElementsByClassName('ql-divider')[0];
        if (dividerBtn) dividerBtn.innerHTML = "&#9866";
        let sourceBtn = document.getElementsByClassName('ql-source')[0];
        if (sourceBtn) sourceBtn.innerHTML = "&#10094 &#10095";
    }, [modalOpen]);

    const toggle = () => setModalOpen(!modalOpen)

    const handleModalSave = (content) => {
        const quill = quillRef.current.getEditor();
        quill.root.innerHTML = content; // Update editor content with modal content
        setModalOpen(false); // Close the modal
    };

    const modules = {
        toolbar: {
            container: toolbarOptions,
            handlers: {
                'source': () => {
                    const quill = quillRef.current.getEditor();
                    toggle()
                    setEditorContent(quill.root.innerHTML)
                },
                'divider': () => {
                    const quill = quillRef.current.getEditor();
                    const range = quill.getSelection();
                    if (range) {
                        quill.insertEmbed(range.index, 'divider', true);
                    }
                },
                'undo': () => {
                    const quill = quillRef.current.getEditor();
                    if (quill.history) {
                        quill.history.undo();
                    }
                },
                'redo': () => {
                    const quill = quillRef.current.getEditor();
                    if (quill.history) {
                        quill.history.redo();
                    }
                }
            }
        },
        history: {
            delay: 1000,
            maxStack: 100,
            userOnly: true
        }
    };

    const formats = [
        "header", "size", "align", "color",
        "bold", "italic", "underline", "strike",
        "blockquote", "list", "bullet", "indent",
        "link", "image", "code-block", "divider",
        "source"
    ];

    const editor = useMemo(() => <ReactQuill
        ref={quillRef}
        theme="snow"
        modules={modules}
        formats={formats}
        placeholder="Enter your text you want to humanize"
        onChange={(content) => setEditorContent(content)}
        className='reactquill'
        style={{ height: 'calc(402px - 66px)',borderRadius:'21px !important' }}
    />, [])

    return (
        <Box>
            <Box>
                {editor}
                <SourceModal
                    open={modalOpen}
                    toggle={toggle}
                    handleSave={handleModalSave}
                    content={editorContent}
                    setEditorContent={setEditorContent}
                />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton aria-label="send" className='editor-send-btn' onClick={() => setTextEditorContent(editorContent)}>
                    <SendIcon />
                </IconButton>
            </Box>
        </Box>
    );
}

export default EditorSection1